<template>
	<div>
		<v-toolbar flat dense dark class="bread-adjust grey darken-4">
			<v-breadcrumbs dark :items="items" divider="/"></v-breadcrumbs>
		</v-toolbar>
  </div>
</template>

<script>
  export default {
  	props: ['items'],
  }
</script>