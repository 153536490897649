<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
	    <v-img
      v-if="$vuetify.breakpoint.mdAndUp"
      dark
      src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/shaq-min.jpg?alt=media&token=46adfa46-6f5d-442b-93b4-271447856165" position="center top">
      <div class="overlay-clear">
        <v-container>
          <div class="display-3">
            <span class="thin">Brand</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Ambassadors</span>
          </div>
        </v-container>
      </div>
    </v-img>
    <v-img
      v-if="$vuetify.breakpoint.smAndDown"
      dark
      :aspect-ratio="1"
      src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/shaq-min.jpg?alt=media&token=46adfa46-6f5d-442b-93b4-271447856165" position="center top">
      <div class="overlay-clear pt-5">
        <div class="pt-5">
        <v-container class="pt-5">
          <div class="display-3 mt-5">
            <span class="thin">Brand</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Ambassadors</span>
          </div>
        </v-container>
        </div>
      </div>
    </v-img>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Stellar Advisory Committee</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Includes elite athletes who value enhanced fan interaction in real time (Justin
Braun, Matt Irwin, Chris Mullin, Shaquille O’Neal, Randy Winn, among others)</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Serve as Brand Ambassadors</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Partner with TechCentrix to build brand awareness</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Build alliances in stadiums and arenas</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Provide social media support</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Brand Ambassadors',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>